import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
    // push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}
const routes = [{
        path: "/",
        redirect: '/home',
        name: "home",
        component: () =>
            import ("../views/home.vue"),
        children: [{
                path: '/',
                alias: "/dashboard",
                name: "dashboard",
                component: () =>
                    import ('../views/dashboard/index.vue')
            },
            {
                path: '/user/info',
                name: "info",
                component: () =>
                    import ('../views/user/info.vue')
            },
            {
                path: '/user/set',
                name: "set",
                component: () =>
                    import ('../views/user/set.vue')
            },
            {
                path: '/user/real-auth',
                name: "realAuth",
                component: () =>
                    import ('../views/user/real-auth.vue')
            },
            {
                path: '/order',
                name: "order",
                component: () =>
                    import ('../views/order/index.vue')
            },
            {
                path: '/order/pay',
                name: "pay",
                component: () =>
                    import ('../views/order/pay.vue')
            }, {
                path: '/api',
                name: "api",
                component: () =>
                    import ('../views/api/index.vue')
            },
            {
                path: '/invokeList',
                name: "invokeList",
                component: () =>
                    import ('../views/api/invokeList.vue')
            }, {
                path: '/secret',
                name: "secret",
                component: () =>
                    import ('../views/secret/index.vue')
            },
            {
                path: "/message",
                name: "message",
                component: () =>
                    import ("../views/message/index.vue"),
                meta: { title: "消息中心" },
            },
            {
                path: "/message/info",
                name: "message-info",
                component: () =>
                    import ("../views/message/info.vue"),
                meta: { title: "消息详情" },
            }
        ]
    },

    {
        path: "/login",
        name: "login",
        component: () =>
            import ("../views/login.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/",
        name: "login",
        component: () =>
            import ("../views/login.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/bindPhone",
        name: "bindPhone",
        component: () =>
            import ("../views/bindPhone.vue"),
        meta: { title: "登录" },
    },
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        scrollBehavior: () => ({ y: 0 }),
        routes: routes,
    })

const router = createRouter()

// 重置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}
//路由前置
router.beforeEach((to, from, next) => {
    if (to.path != '/login' && to.path != '/bindPhone') {
        if (localStorage.getItem("token") && localStorage.getItem("fortressExpiredTime") && new Date(Date.parse(localStorage.getItem("fortressExpiredTime"))) > new Date()) {
            next()
        } else {
            router.replace({ name: 'login' })
        }
    } else {
        next()
    }

})
export default router
