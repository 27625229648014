import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        collapse: localStorage.getItem('collapse') || false,
        asideWidth: localStorage.getItem("asideWidth") || 180,
        token: localStorage.getItem("token") || "",
        userInfo: localStorage.getItem("userInfo") || {},
    },
    mutations: {
        setCollapse(state, val) {
            state.collapse = val
            localStorage.setItem("collapse", val)
        },
        setAsideWidth(state, val) {
            state.asideWidth = val
            localStorage.setItem("asideWidth", val)
        },
        setToken(state, token) {
            state.token = token
            localStorage.setItem("token", token)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = JSON.stringify(userInfo)
            localStorage.setItem("userInfo", JSON.stringify(userInfo))
        },
    },
    actions: {},
    modules: {},
});