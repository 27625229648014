import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import FortressCopy from '@/components/fortress-copy'
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入动画
import animate from 'animate.css';
animate
//引入初始化样式文件
import "normalize.css";
import "@/assets/css/common.scss";
// 引入element的隐藏类
import 'element-ui/lib/theme-chalk/display.css';
// 引入echarts
let echarts = require("echarts")
Vue.prototype.$echarts = echarts
//引入公共组件
// 引入jq
import $ from 'jquery'
Vue.prototype.$ = $
Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(FortressCopy)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");